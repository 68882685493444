<template>
  <div v-if="visible" class="popup-modal">
    <div class="popup-content mainland-popup">
      <span class="popup-close" @click="$emit('close')">&times;</span>
      <div class="popup-modal-title mainland-title">付款</div>
      <div class="popup-modal-desc mainland-desc">
        <span class="payment-icon">
          <img src="/ssr/img/payment/alipay.svg" alt="支付宝" class="payment-method-icon">
        </span>支付宝 或
        <span class="payment-icon">
          <img src="/ssr/img/payment/wechat.svg" alt="微信支付" class="payment-method-icon">
        </span>微信支付 只能用于一次性购买，无法进行连续订阅。
        如果打开的购买页面中未出现支付宝或微信支付的选项，可能是由于你的IP地址不在允许的国家或地区范围内。
      </div>
      <div class="payment-options">
        <div class="payment-title">请选择你要购买的方案：</div>
        <div class="payment-plan-list">
          <label v-for="(plan, index) in paymentPlans" :key="index" class="plan-option">
            <input 
              type="radio" 
              :value="plan.id" 
              v-model="selectedPlan"
              name="payment-plan"
            >
            <span class="plan-label">{{ plan.label }}</span>
          </label>
        </div>
      </div>
      <button class="popup-btn mainland-btn" @click.prevent="handleContinue">继续</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMainland',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 添加新的 prop
    onCheckout: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      selectedPlan: null,
      paymentPlans: [
        { id: 'starter_onepay_month', type_name: 'starter_onepay_month', label: '入门版 - 单月 (¥48元)' },
        { id: 'pro_onepay_month', type_name: 'pro_onepay_month', label: '专业版 - 单月 (¥138元)' },
        { id: 'starter_onepay_year', type_name: 'starter_onepay_year', label: '入门版 - 整年(50% OFF) (¥398元)' },
        { id: 'pro_oneway_year', type_name: 'pro_oneway_year', label: '专业版- 整年(60%OFF) (¥998元)' }
      ]
    }
  },
  methods: {
    handleContinue() {
      if (this.selectedPlan) {
        const selectedPlanData = this.paymentPlans.find(plan => plan.id === this.selectedPlan);
        this.onCheckout(selectedPlanData);
        // this.$emit('continue', this.selectedPlan)
      }
    }
  }
}
</script>
